import * as React from 'react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { SCREEN } from '../styles/screens'
// COMPONENTS
import Layout from 'layouts/main'
import axios from 'axios'
import { config } from '../config'
import { Input } from '../components/styled/input'
import { Button } from '../components/styled/button'
import queryString from 'querystring'

const InputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > div {
    margin: 8px 0;
    flex: 1 1 80%;
  }

  > button {
    width: 100%;
    margin: 12px 0;
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    > div {
      margin: 2px 0;
    }

    > button {
      margin: 12px 0 0 0;
    }
  }
`
const defaultLoginFields = { email: '', password: '' }

const OAuthPage = () => {

  const [showLogin, setShowLogin] = useState(false);
  const [joining, setJoining] = useState(false);
  const [loginFields, setLogiFields] = React.useState({ ...defaultLoginFields })

  const onChangeLoginFields = e => {
    let r = { ...loginFields }
    r[e.target.id] = e.target.value
    setLogiFields(r)
  }

  const onLogin = async () => {
    setJoining(true)
    try {
      let user = (
        await axios.post(`${config.API_BASE_URL}/users/login`, loginFields)
      ).data
      const token = user.apiTokens.pop();
      setJoining(false)
      setLogiFields({ ...defaultLoginFields })

      const {next} = queryString.parse(window.location.search.substr(1));
      if(next){
        window.location.href = next;
      }else {
        window.location.href = `/profile`
      }
    } catch (error) {
      setJoining(false)
      alert('Invalid credentials')
    }
  }

  const sendToLogin = () => {
    window.location.href = '/?showLogin=true&next=/oauth'
  }

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (!token) {
      sendToLogin();
      return
    }
    axios.get(`${config.API_BASE_URL}/users/profile?token=${token}`).then((res) => {
      if (!res.data) {
        sendToLogin();
        return
      }
      const allowedUrls = ['https://eduai.buildup.am','http://localhost:3000'];

      window.location.href = 'https://edu-ai.buildup.am/login?token=' + token;
    }).catch((e) => {
      sendToLogin();
    });
  }, [])

  return <Layout title='Profile'>
    {
      showLogin && <InputsContainer>
        <Input
          id="email"
          value={loginFields.email}
          onChange={onChangeLoginFields}
          label="Email"
          placeholder="example@email.com"
          type="email"
        />
        <Input
          id="password"
          value={loginFields.password}
          onChange={onChangeLoginFields}
          label="Password"
          placeholder="••••••••"
          type="password"
        />
        <Button disabled={joining} onClick={onLogin}>
          {joining ? 'Signing in' : 'Sign in'}
        </Button>
      </InputsContainer>
    }
  </Layout>
}
export default OAuthPage
